import {
  BaseKey,
  file2Base64,
  useCreate,
  useMany,
  useOne,
  useRouterContext,
  useUpdate,
} from '@refinedev/core'
import {
  Button,
  Card,
  Col,
  Collapse,
  Descriptions,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  Tabs,
  Typography,
} from 'antd'
import { RowRemoveButton } from 'components/button'
import { InlineCRUD } from 'components/crud/inlineCRUD'
import { AutoGeneratedPasswordInput, SingleImageUpload } from 'components/input'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { VenueType } from 'interfaces/crm'
import { UseFormReturnType } from 'interfaces/refine'
import {
  AreaType,
  ICatering,
  IKeyLocation,
  IMediaSection,
  INearbyAccommodation,
  IOverallPackage,
  IServicePackage,
  IVenue,
  IVenueRental,
  OverallPackageType,
} from 'interfaces/venue'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {
  CATERING_URL,
  KEY_LOCATION_URL,
  MEDIA_SECTION_URL,
  NEARBY_ACCOMMODATION_URL,
  OVERALL_PACKAGE_URL,
  REGION_URL,
  SERVICE_PACKAGE_URL,
  VENUE_RENTAL_URL,
  VENUE_URL,
} from 'urls'
import { createOptionsFromEnum, generatePassword } from 'utils/common'
import { handleBase64Image } from 'utils/form'
import { CateringDetails } from './catering/Details'
import { CateringForm } from './catering/Form'
import { KeyLocationDetails } from './keyLocation/Details'
import { KeyLocationForm } from './keyLocation/Form'
import { NearbyAccommodationDetails } from './nearbyAccommodation/Details'
import { NearbyAccommodationForm } from './nearbyAccommodation/Form'
import { OverallPackageDetails } from './overallPackage/Details'
import { OverallPackageForm } from './overallPackage/Form'
import { ServicePackageDetails } from './servicePackage/Details'
import { ServicePackageForm } from './servicePackage/Form'
import { MediaSectionDetails } from './venueMedia/Details'
import { MediaSectionForm } from './venueMedia/Form'
import { VenueRentalDetails } from './venueRentals/Details'
import { VenueRentalForm } from './venueRentals/Form'
import { ICustomer } from 'interfaces/customer'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { useModal } from '@refinedev/antd'
import { useState } from 'react'
import { IStaff } from 'interfaces/staff'

export const VenueForm = ({
  useFormProps,
}: {
  useFormProps: UseFormReturnType<IVenue>
}) => {
  const { useParams } = useRouterContext()
  const { id } = useParams<any>()
  const { formProps, queryResult } = useFormProps
  const [generatedPassword, setGeneratedPassword] = useState<string>('')
  const [memberId, setMemberId] = useState<BaseKey>()
  const { modalProps, show, close } = useModal()
  const { mutate: changePassword, isLoading: changePasswordIsLoading } =
    useUpdate<IStaff>()
  const { mutate: venuePost } = useCreate()
  const onFinish = async (values: any) => {
    let imageString = ''

    const { logo, accommodation, catering, map_image } = values

    // remove the cover_image key as it holds the s3 url or null
    // we dont want to patch with that
    delete values.logo
    delete values.map_image

    if (logo) {
      const file = logo[logo?.length - 1]

      if (file.originFileObj) {
        const base64String = await file2Base64(file)
        imageString = base64String
      }
    }

    if (accommodation) {
      for (const ele of accommodation) {
        if (ele.image) {
          const file = ele.image[ele.image.length - 1]
          if (file.originFileObj) {
            ele.image = await file2Base64(file)
          } else {
            delete ele.image
          }
        }
      }
    }

    if (catering) {
      for (const ele of catering) {
        if (ele.image) {
          const file = ele.image[ele.image.length - 1]
          if (file.originFileObj) {
            ele.image = await file2Base64(file)
          } else {
            delete ele.image
          }
        }
        if (ele.thumbnail) {
          const file = ele.thumbnail[ele.thumbnail.length - 1]
          if (file.originFileObj) {
            ele.thumbnail = await file2Base64(file)
          } else {
            delete ele.thumbnail
          }
        }
      }
    }

    const finalResponse = { ...values, accommodation, catering }
    if (map_image) {
      const finalImage = await handleBase64Image(map_image)
      if (finalImage.length) finalResponse['map_image'] = finalImage
    }

    if (imageString.length) finalResponse['logo'] = imageString
    return formProps.onFinish && formProps.onFinish(finalResponse)
  }

  const { data: membersData } = useMany<ICustomer>({
    resource: 'auth/users',
    ids: queryResult?.data?.data?.members || [],
    queryOptions: {
      enabled: !!queryResult?.data?.data?.members.length,
    },
  })

  const handleRemoveMember = (member_id: string) => {
    venuePost(
      {
        resource: `package_tool/venues/${id}/remove_member`,
        values: {
          member_id,
        },
      },
      { onSuccess: () => queryResult?.refetch() },
    )
  }

  const { data: venue_types } = useOne<any>({
    resource: VENUE_URL,
    id: 'venue_types',
  })

  return (
    <Form {...formProps} layout="vertical" onFinish={onFinish}>
      <Tabs>
        <Tabs.TabPane tab="Basic" key="basic">
          <SingleImageUpload
            label="Logo"
            name="logo"
            buttonText="Image"
            initialImageUrl={queryResult?.data?.data?.logo}
          />
          <Form.Item label="Name" name="name">
            <Input />
          </Form.Item>
          <Form.Item
            label="Slug"
            name="slug"
            help="Leave blank to auto generate">
            <Input />
          </Form.Item>
          <Form.Item label="Description" name="description" initialValue={''}>
            <ReactQuill theme="snow" />
          </Form.Item>
          <Form.Item label="Discount" name="discount">
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            label="Personal"
            name="is_personal"
            valuePropName="checked">
            <Switch />
          </Form.Item>
          <Form.Item
            label="Important"
            name="is_important"
            valuePropName="checked">
            <Switch />
          </Form.Item>
          <Form.Item label="Venue Type" name="venue_type">
            <Select
              allowClear
              dropdownMatchSelectWidth={false}
              options={venue_types?.data.results.map((t: any) => ({
                label: t,
                value: t,
              }))}
            />
          </Form.Item>
          <Form.Item label="Category" name="category">
            <Select options={createOptionsFromEnum(OverallPackageType)} />
          </Form.Item>
          <Form.Item
            label="Region"
            name="region"
            rules={[
              {
                required: true,
                message: 'This is required',
              },
            ]}>
            <SelectWithDefault
              useSelectProps={{ resource: REGION_URL, optionLabel: 'name' }}
            />
          </Form.Item>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Extra Info" key="basic_info" forceRender>
          <Form.Item
            label="Has Accommodation"
            name="has_accommodation"
            valuePropName="checked">
            <Switch />
          </Form.Item>
          <Form.Item
            label="Is Published"
            name="is_published"
            valuePropName="checked">
            <Switch />
          </Form.Item>
          <Collapse>
            <Collapse.Panel header="Capacity" key="capacity">
              <Form.Item label="Seating Capacity" name="seating_capacity">
                <InputNumber />
              </Form.Item>
              <Form.Item label="Floating Capacity" name="floating_capacity">
                <InputNumber />
              </Form.Item>
            </Collapse.Panel>
            <Collapse.Panel header="Parking" key="parking">
              <Form.Item
                label="Parking Available?"
                name="parking_available"
                valuePropName="checked">
                <Switch />
              </Form.Item>
              <Form.Item label="Car Count" name="parking_car_count">
                <InputNumber />
              </Form.Item>
              <Form.Item
                label="Valet Available?"
                name="valet_available"
                valuePropName="checked">
                <Switch />
              </Form.Item>
            </Collapse.Panel>
            <Collapse.Panel header="Relation" key="Relation">
              <Form.Item
                label="Empanelled"
                name="is_empanelled"
                valuePropName="checked">
                <Switch />
              </Form.Item>
              <Form.Item
                label="Partnership"
                name="is_partnership"
                valuePropName="checked">
                <Switch />
              </Form.Item>
              <Form.Item
                label="Enrolled"
                name="is_enrolled"
                valuePropName="checked">
                <Switch />
              </Form.Item>
            </Collapse.Panel>
          </Collapse>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Address" key="address" forceRender>
          <Collapse>
            <Collapse.Panel header="Address" key="address">
              <SingleImageUpload
                label="Map Image"
                buttonText="Image"
                name={'map_image'}
                initialImageUrl={queryResult?.data?.data.map_image}
              />
              <Form.Item label="Area Type" name="area">
                <Select options={createOptionsFromEnum(AreaType)} />
              </Form.Item>
              <Form.Item name={['line_1']} label="Line 1">
                <Input />
              </Form.Item>
              <Form.Item name={['line_2']} label="Line 2">
                <Input />
              </Form.Item>
              <Form.Item name={['city']} label="City">
                <Input />
              </Form.Item>
              <Form.Item name={['state']} label="State">
                <Input />
              </Form.Item>
              <Form.Item name={['pincode']} label="Pincode">
                <Input />
              </Form.Item>
              <Form.Item name={['google_map_link']} label="Google Map Link">
                <Input />
              </Form.Item>
              <Form.Item name={['spoc_contact']} label="SPOC Contact">
                <Input />
              </Form.Item>
            </Collapse.Panel>
            <Collapse.Panel header="Key Locations" key="key_location">
              <InlineCRUD<IKeyLocation>
                resource={KEY_LOCATION_URL}
                renderItem={(item) => <KeyLocationDetails item={item} />}
                name="Key Locations"
                filters={{
                  permanent: [{ field: 'venue', operator: 'eq', value: id }],
                }}
                renderCreateForm={(formProps) => (
                  <KeyLocationForm formProps={formProps} venueId={id} />
                )}
                renderEditForm={(formProps) => (
                  <KeyLocationForm formProps={formProps} venueId={id} />
                )}
              />
            </Collapse.Panel>
          </Collapse>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Accommodation" key="accommodation" forceRender>
          <Collapse>
            <Collapse.Panel
              header="Accommodation"
              key="accommodation"
              forceRender>
              <Form.List name="accommodation">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field) => (
                      <div key={`accommodation ${field.key}`}>
                        <Card
                          actions={[
                            <RowRemoveButton
                              key="remove"
                              onClick={() => remove(field.name)}
                            />,
                          ]}>
                          <Form.Item
                            {...field}
                            label="Name"
                            name={[field.name, 'name']}
                            rules={[
                              {
                                required: true,
                                message: 'This is required',
                              },
                            ]}>
                            <Input />
                          </Form.Item>
                          <SingleImageUpload
                            label="Image"
                            buttonText="Image"
                            name={[field.name, 'image']}
                            initialImageUrl={
                              queryResult?.data?.data.accommodation[field.name]
                                ?.image
                            }
                          />
                          <Form.Item
                            {...field}
                            label="Price (Per Room)"
                            name={[field.name, 'price']}
                            rules={[
                              {
                                required: true,
                                message: 'This is required',
                              },
                            ]}>
                            <InputNumber style={{ width: '100%' }} />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            label="Number Of Rooms"
                            name={[field.name, 'number_of_rooms']}>
                            <InputNumber style={{ width: '100%' }} />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            label="Is included in rental?"
                            name={[field.name, 'is_included_in_rental']}
                            valuePropName="checked">
                            <Switch />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            label="Help Text"
                            name={[field.name, 'help_text']}>
                            <Input.TextArea />
                          </Form.Item>
                        </Card>
                        <Divider />
                      </div>
                    ))}
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block>
                        Add Accommodation
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Collapse.Panel>
            <Collapse.Panel header="Rentals" key="rental">
              <InlineCRUD<IVenueRental>
                resource={VENUE_RENTAL_URL}
                name="Rentals"
                filters={{
                  permanent: [{ field: 'venue', operator: 'eq', value: id }],
                }}
                renderItem={(item) => <VenueRentalDetails item={item} />}
                renderCreateForm={(formProps) => (
                  <VenueRentalForm formProps={formProps} venueId={id} />
                )}
                renderEditForm={(formProps) => (
                  <VenueRentalForm formProps={formProps} venueId={id} />
                )}
              />
            </Collapse.Panel>
            <Collapse.Panel header="Nearby Accommodation" key="nearby">
              <InlineCRUD<INearbyAccommodation>
                resource={NEARBY_ACCOMMODATION_URL}
                name="Rentals"
                filters={{
                  permanent: [
                    { field: 'venue', operator: 'eq', value: id },
                    { field: 'expand', operator: 'eq', value: 'venue_option' },
                  ],
                }}
                renderItem={(item) => (
                  <NearbyAccommodationDetails item={item} />
                )}
                renderCreateForm={(formProps) => (
                  <NearbyAccommodationForm formProps={formProps} venueId={id} />
                )}
                renderEditForm={(formProps) => (
                  <NearbyAccommodationForm formProps={formProps} venueId={id} />
                )}
              />
            </Collapse.Panel>
          </Collapse>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Catering" key="catering" forceRender>
          <Space>
            <Form.Item
              label="Has Catering"
              name="has_catering"
              valuePropName="checked">
              <Switch />
            </Form.Item>
            <Form.Item
              label="In House"
              name="catering_in_house"
              valuePropName="checked">
              <Switch />
            </Form.Item>
            <Form.Item
              label="Out House"
              name="catering_out_house"
              valuePropName="checked">
              <Switch />
            </Form.Item>
            <Form.Item
              label="Alcohol Allowed"
              name="alcohol_allowed"
              valuePropName="checked">
              <Switch />
            </Form.Item>
          </Space>
          <Collapse>
            <Collapse.Panel header="Meals" key="meals">
              <InlineCRUD<ICatering>
                resource={CATERING_URL}
                name="Meals"
                filters={{
                  permanent: [{ field: 'venue', operator: 'eq', value: id }],
                }}
                renderItem={(item) => <CateringDetails item={item} />}
                renderCreateForm={(formProps) => (
                  <CateringForm formProps={formProps} venueId={id} />
                )}
                renderEditForm={(formProps) => (
                  <CateringForm formProps={formProps} venueId={id} />
                )}
              />
            </Collapse.Panel>
            <Collapse.Panel
              header="Meals (Deprecated)"
              key="meal_old"
              forceRender>
              <Form.List name="catering">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field) => (
                      <div key={`catering ${field.key}`}>
                        <Card
                          actions={[
                            <RowRemoveButton
                              key="remove"
                              onClick={() => remove(field.name)}
                            />,
                          ]}>
                          <Form.Item
                            {...field}
                            label="Name"
                            name={[field.name, 'name']}>
                            <Input />
                          </Form.Item>
                          <SingleImageUpload
                            label="Thumbnail"
                            buttonText="Thumbnail"
                            name={[field.name, 'thumbnail']}
                            initialImageUrl={
                              queryResult?.data?.data.catering[field.name]
                                ?.thumbnail
                            }
                          />
                          <SingleImageUpload
                            label="Image"
                            buttonText="Image"
                            name={[field.name, 'image']}
                            initialImageUrl={
                              queryResult?.data?.data.catering[field.name]
                                ?.image
                            }
                          />
                          <Form.Item
                            {...field}
                            label="Price (Per Pax)"
                            name={[field.name, 'price']}>
                            <InputNumber style={{ width: '100%' }} />
                          </Form.Item>
                        </Card>
                        <Divider />
                      </div>
                    ))}
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block>
                        Add Catering
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Collapse.Panel>
          </Collapse>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Packages" key="packages">
          <Collapse>
            <Collapse.Panel header="Overall Packages" key="overallPackages">
              <InlineCRUD<IOverallPackage>
                resource={OVERALL_PACKAGE_URL}
                name="Overall Packages"
                filters={{
                  permanent: [{ field: 'venue', operator: 'eq', value: id }],
                }}
                renderItem={(item) => <OverallPackageDetails item={item} />}
                renderCreateForm={(formProps) => (
                  <OverallPackageForm formProps={formProps} venueId={id} />
                )}
                renderEditForm={(formProps) => (
                  <OverallPackageForm formProps={formProps} venueId={id} />
                )}
              />
            </Collapse.Panel>
            <Collapse.Panel header="Service Packages" key="servicePackages">
              <InlineCRUD<IServicePackage>
                resource={SERVICE_PACKAGE_URL}
                name="Service Packages"
                filters={{
                  permanent: [{ field: 'venue', operator: 'eq', value: id }],
                }}
                renderItem={(item) => <ServicePackageDetails item={item} />}
                renderCreateForm={(formProps) => (
                  <ServicePackageForm formProps={formProps} venueId={id} />
                )}
                renderEditForm={(formProps) => (
                  <ServicePackageForm formProps={formProps} venueId={id} />
                )}
              />
            </Collapse.Panel>
          </Collapse>
        </Tabs.TabPane>{' '}
        <Tabs.TabPane tab="Media" key="media">
          <InlineCRUD<IMediaSection>
            resource={MEDIA_SECTION_URL}
            name="Media"
            filters={{
              permanent: [{ field: 'venue', operator: 'eq', value: id }],
            }}
            renderItem={(item) => <MediaSectionDetails item={item} />}
            renderCreateForm={(formProps) => (
              <MediaSectionForm formProps={formProps} venueId={id} />
            )}
            renderEditForm={(formProps) => (
              <MediaSectionForm formProps={formProps} venueId={id} />
            )}
          />
        </Tabs.TabPane>
        {!!queryResult?.data?.data?.members.length && (
          <Tabs.TabPane tab="Members" key={'members'}>
            <Row>
              {membersData?.data.map((member) => (
                <Col key={member.id} xs={12} sm={8} md={6} lg={6} xl={6}>
                  {member.id}
                  <Card
                    actions={[
                      <Button
                        key="remove"
                        onClick={() => handleRemoveMember(member.id)}
                        icon={<DeleteOutlined />}
                        danger
                        size="small">
                        Remove
                      </Button>,
                      <Button
                        key="change password"
                        onClick={() => {
                          show()
                          setMemberId(member.id)
                        }}
                        icon={<EditOutlined />}
                        size="small">
                        Password
                      </Button>,
                    ]}
                    title={member.username}>
                    <Descriptions>
                      <Descriptions.Item label="Name">
                        {member.first_name} {member.last_name}
                      </Descriptions.Item>
                      <Descriptions.Item label="Email">
                        {member.email}
                      </Descriptions.Item>
                    </Descriptions>
                    <Modal
                      onOk={() => {
                        changePassword(
                          {
                            resource: 'package_tool/venues',
                            id: `${memberId}/change_password`,
                            values: { password: generatedPassword },
                          },
                          {
                            onSuccess: () => {
                              setGeneratedPassword('')
                              close()
                            },
                          },
                        )
                      }}
                      {...modalProps}
                      confirmLoading={changePasswordIsLoading}>
                      <Space direction="vertical">
                        <Typography.Title level={5}>
                          Generating password for {member.username}
                        </Typography.Title>
                        <AutoGeneratedPasswordInput
                          value={generatedPassword}
                          onChange={setGeneratedPassword}
                          onClick={() =>
                            setGeneratedPassword(generatePassword())
                          }
                        />
                      </Space>
                    </Modal>
                  </Card>
                </Col>
              ))}
            </Row>
          </Tabs.TabPane>
        )}
      </Tabs>
    </Form>
  )
}
