import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button as MDButton,
  Drawer as MDDrawer,
  Text,
} from '@meragi/meragi-design'

import {
  DeleteButton,
  EditButton,
  List,
  RefreshButton,
  getDefaultSortOrder,
  useDrawerForm,
  useModalForm,
  useTable,
} from '@refinedev/antd'

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons'

import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Drawer,
  Flex,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Table,
  Tag,
  TimeRangePickerProps,
  Tooltip,
  Typography,
  // Day
} from 'antd'

import {
  BaseKey,
  parseTableParams,
  useCreate,
  useDelete,
  useDeleteMany,
  useGetIdentity,
  useModal,
  useOne,
  useRouterContext,
  useUpdate,
} from '@refinedev/core'

import { RowRemoveButton } from 'components/button'
import { DateFieldFromNow, PriceField } from 'components/field'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { SortableItem, SortableList } from 'components/list/sortableList'
import { LeadContext } from 'contexts/lead'
import { ILead, ISavedFilter, ITracker, sourceOptions } from 'interfaces/crm'
import { IStaff } from 'interfaces/staff'
import { useEffect, useMemo, useState } from 'react'
import {
  CRM_PREFERENCES_URL,
  LEAD_SERVICE_URL,
  LEAD_URL,
  SAVED_FILTERS_URL,
  SERVICE_URL,
  STAFF_URL,
  VENUE_URL,
} from 'urls'
import {
  cleanBool,
  createOptionsFromEnum,
  getUserName,
  mergeMaps,
} from 'utils/common'
import { getSearchFormInitialValue } from 'utils/filter'
import { LeadForm } from './Form'
import { BookingModal } from './components/BookingModal'
import { BookingProbability } from './components/BookingProbability'
import { CallAttemptInput } from './components/CallAttemptInput'
import { CallLogModal } from './components/CallLogModal'
import { ChangeSelect } from './components/ChangeSelect'
import { ConsultantSelect } from './components/ConsultantSelect'
import { CreatePackageModal } from './components/CreatePackageModal'
import { BudgetInput, CityInput } from './components/EdiatableComponents'
import { EventsModal } from './components/EventsModal'
import { ServiceStatusTable } from './components/ExpandableLeadServiceTable'
import { InfoModal } from './components/InfoModal'
import { NotesInput } from './components/NotesInput'
import { QuizDataModal } from './components/QuizDataModal'
import { ServicesModal } from './components/ServicesModal'
import { ALWAYS_PRESENT_COLUMNS, CRM_COLUMNS } from './consts'
import VenueRequirements from './components/venueRequirementComponents/VenueRequirements'
import { NewVenueRequirement } from './components/NewVenueReqFlow/NewVenueRequirement'
import { ProfileModal } from './components/ProfileModal'
import dayjs from 'antd/node_modules/dayjs'
import PreSaleQuiz from './components/PreSaleQuiz'
import type { Dayjs } from 'dayjs'
import { Cities } from 'interfaces/crm'
import { TagEditModal } from './components/TagModal'

const crmFilterFieldFormatter = {
  pre_sales: (value: any) =>
    !!value ? value?.map((id: any) => Number(id)) : undefined,
  relationship_manager: (value: any) =>
    !!value ? value?.map((id: any) => Number(id)) : undefined,
  consultant: (value: any) =>
    !!value ? value?.map((id: any) => Number(id)) : undefined,
  services__consultant: (value: any) =>
    !!value ? value?.map((id: any) => Number(id)) : undefined,
  relationship_manager__isnull: (value: any) => cleanBool(value),
  pre_sales__isnull: (value: any) => cleanBool(value),
  consultant__isnull: (value: any) => cleanBool(value),
  services__consultant__isnull: (value: any) => cleanBool(value),
  venue: (value: any) =>
    !!value ? value?.map((id: any) => Number(id)) : undefined,
  venue__isnull: (value: any) => cleanBool(value),
  is_quiz_filled: (value: any) => cleanBool(value),
  is_venue_decided: (value: any) => cleanBool(value),
  created_at: (value: any) => value.map((date: string) => dayjs(date)),
  bookings__date: (value: string[]) => {
    return value.map((date: string) => dayjs(date))
  },
  progress: (value: any = []) => {
    return value?.map((item: string) => {
      if (!item) return undefined
      const [change, created_at] = item.split('!')
      return {
        change,
        created_at: created_at.split(',').map((date: string) => dayjs(date)),
      }
    })
  },
  services: (value: any) =>
    value?.map((item: string) => {
      if (!item) return undefined
      const [service, status] = item.split('!')
      return {
        service: Number(service),
        status: status.split(','),
      }
    }),
  profile__isnull: (value: any) => cleanBool(value),
  is_important: (value: any) => cleanBool(value),
  profile__rql: (value: any) => cleanBool(value),
}

const StaffFormItem = ({ name, label }: { name: string; label?: string }) => {
  return (
    <Row justify="space-between" gutter={[16, 16]}>
      <Col span={16}>
        <Form.Item name={name} label={label}>
          <SelectWithDefault<IStaff>
            useSelectProps={{
              resource: STAFF_URL,
            }}
            dropdownMatchSelectWidth={false}
            allowClear
            mode="multiple"
            renderOptions={(staffs) =>
              staffs?.map((staff) => (
                <Select.Option key={staff.id} value={staff.id}>
                  {getUserName(staff)}
                </Select.Option>
              ))
            }
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item name={`${name}__isnull`} label={<></>} initialValue={null}>
          <Select defaultActiveFirstOption>
            <Select.Option value={null}>All</Select.Option>
            <Select.Option value={false}>Is Not Empty</Select.Option>
            <Select.Option value={true}>Is Empty</Select.Option>
          </Select>
        </Form.Item>
      </Col>
    </Row>
  )
}

const FilterDrawer = (props: any) => {
  type VenueType =
    | 'hotel_resort'
    | 'wedding_venue'
    | 'banquet_hall_kalyana_mandapa'

  const venueTypeOptions = [
    { label: 'Hotel/Resort', value: 'hotel_resort' },
    { label: 'Wedding venue', value: 'wedding_venue' },
    {
      label: 'Banquet Hall & Kalyana Mandapa',
      value: 'banquet_hall_kalyana_mandapa',
    },
  ]

  const venuePriceOptions = [
    { label: '3 Star', value: '3 Star' },
    { label: '4 Star', value: '4 Star' },
    { label: '5 Star', value: '5 Star' },
    { label: '5 Star Plus', value: '5 Star Plus' },
    { label: '80K-3L', value: '80K-3L' },
    { label: '3L-7L', value: '3L-7L' },
    { label: '7L-13L', value: '7L-13L' },
    { label: 'More than 13L', value: 'more than 13L' },
  ]

  const { formProps, modalProps, show } = useModalForm({
    resource: SAVED_FILTERS_URL,
    action: 'create',
    redirect: false,
  })

  const { data: stageData } = useOne<ITracker[]>({
    resource: LEAD_URL,
    id: 'stage_list',
    queryOptions: {
      enabled: props.visible,
    },
  })

  const { data: serviceStageData } = useOne<ITracker[]>({
    resource: LEAD_SERVICE_URL,
    id: 'status_list',
    queryOptions: {
      enabled: props.visible,
    },
  })

  const { data: statusData } = useOne<ITracker[]>({
    resource: LEAD_URL,
    id: 'status_list',
    queryOptions: {
      enabled: props.visible,
    },
  })

  const { useLocation } = useRouterContext()

  const currentLocation = useLocation()

  return (
    <Drawer
      open={props.visible}
      onClose={props.close}
      width={500}
      extra={
        <Space>
          <Button
            type="primary"
            onClick={() => props.searchFormProps.form?.submit()}>
            Apply
          </Button>
          <Button
            onClick={() => {
              props.searchFormProps.form?.submit()
              show()
            }}>
            Apply and Save
          </Button>
        </Space>
      }>
      <Form
        {...props.searchFormProps}
        layout="vertical"
        size="middle"
        initialValues={() => {
          const initialValues = getSearchFormInitialValue(
            props.filters,
            crmFilterFieldFormatter,
          )
          initialValues.event_horizon = initialValues.event_horizon
            ? dayjs(initialValues.event_horizon, 'MMMM YYYY')
            : null
        }}>
        <Form.Item name="search" label="Search">
          <Input allowClear />
        </Form.Item>
        <Space
          direction="horizontal"
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
          }}>
          <Form.Item name="profile__isnull" label="Profile Filled">
            <Select
              allowClear
              popupMatchSelectWidth={false}
              options={[
                { value: true, label: 'Not Filled' },
                { value: false, label: 'Filled' },
              ]}
            />
          </Form.Item>
          <Form.Item name="is_important" label="Is Important">
            <Select
              allowClear
              popupMatchSelectWidth={false}
              options={[
                { value: true, label: 'Is Important' },
                { value: false, label: 'Not Important' },
              ]}
            />
          </Form.Item>
          <Form.Item name="is_quiz_filled" label="Quiz Filled">
            <Select
              allowClear
              popupMatchSelectWidth={false}
              options={[
                { value: true, label: 'Filled' },
                { value: false, label: 'Not Filled' },
              ]}
            />
          </Form.Item>
          <Form.Item name="is_venue_decided" label="Venue Decided">
            <Select
              allowClear
              popupMatchSelectWidth={false}
              options={[
                { value: true, label: 'Decided' },
                { value: false, label: 'Not Decided' },
              ]}
            />
          </Form.Item>
          <Form.Item name="profile__rql" label="RQL">
            <Select
              allowClear
              popupMatchSelectWidth={false}
              options={[
                { value: true, label: 'Revspot Qualified' },
                { value: false, label: 'Revspot Disqualified' },
              ]}
            />
          </Form.Item>
        </Space>
        <Form.Item name="created_at" label="Created At">
          <DatePicker.RangePicker
            presets={[
              { label: 'Today', value: [dayjs(), dayjs()] },
              {
                label: 'This Month',
                value: [dayjs().startOf('month'), dayjs().endOf('month')],
              },
              {
                label: 'This Week',
                value: [dayjs().startOf('week'), dayjs().endOf('week')],
              },
            ]}
          />
        </Form.Item>
        <Form.Item name="source" label="Source">
          <Input allowClear />
        </Form.Item>
        <Space>
          <Form.Item name="budget_gt" label="Budget">
            <InputNumber placeholder="from" />
          </Form.Item>
          {'-'}
          <Form.Item name="budget_lt" label="Budget">
            <InputNumber placeholder="to" />
          </Form.Item>
        </Space>
        <Form.Item name="campaign" label="Campaign">
          <Input allowClear />
        </Form.Item>
        <Form.Item name="city" label="City">
          <Select
            allowClear
            mode="tags"
            options={createOptionsFromEnum(Cities)}
            placeholder="Select or type a city"
          />
        </Form.Item>
        <Form.Item name="tags" label="Tags">
          <Select allowClear mode="tags" placeholder="enter tags" />
        </Form.Item>
        <StaffFormItem name="pre_sales" label="Pre Sales" />
        <StaffFormItem
          name="relationship_manager"
          label="Relationship Manager"
        />
        <StaffFormItem name="consultant" label="Consultant" />
        <StaffFormItem name="services__consultant" label="Service Consultant" />
        <Form.Item name="stage" label="Stage">
          <Select
            allowClear
            dropdownMatchSelectWidth={false}
            options={stageData?.data.map((t: any) => ({
              label: t.name,
              value: t.name,
            }))}
            mode="multiple"
          />
        </Form.Item>
        <Form.Item name="status" label="Status">
          <Select
            allowClear
            dropdownMatchSelectWidth={false}
            options={statusData?.data.map((t: any) => ({
              label: t.name,
              value: t.name,
            }))}
            mode="multiple"
          />
        </Form.Item>
        <Form.Item name="event_horizon" label="Event Horizon">
          <DatePicker
            format="MMMM YYYY"
            picker="month"
            placeholder="Select Month and Year"
          />
        </Form.Item>
        <Row gutter={[16, 16]}>
          <Col span={16}>
            <Form.Item name="venue" label="Venue">
              <SelectWithDefault
                allowClear
                dropdownMatchSelectWidth={false}
                useSelectProps={{
                  resource: VENUE_URL,
                  optionLabel: 'name',
                }}
                mode="multiple"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="venue__isnull" label={<></>} initialValue={null}>
              <Select defaultActiveFirstOption>
                <Select.Option value={null}>All</Select.Option>
                <Select.Option value={false}>Is Not Empty</Select.Option>
                <Select.Option value={true}>Is Empty</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="venue_suggestions" label="Venue Suggestions">
          <SelectWithDefault
            allowClear
            useSelectProps={{
              resource: VENUE_URL,
              optionLabel: 'name',
            }}
            mode="multiple"
          />
        </Form.Item>
        <Row gutter={[16, 16]}>
          <Col span={16}>
            <Form.Item name="venue_type" label="Venue Type">
              <Select
                mode="multiple"
                options={venueTypeOptions}
                allowClear
                placeholder="Select Venue Type"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="venue_price" label="Venue Price">
              <Select
                mode="multiple"
                options={venuePriceOptions}
                allowClear
                placeholder="Select Venue Price"
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name={['booking_probability']} label="Booking Probability">
          <Select
            mode="multiple"
            options={[
              {
                label: 'high',
                value: 'high',
              },
              {
                label: 'medium',
                value: 'medium',
              },
              {
                label: 'low',
                value: 'low',
              },
            ]}
          />
        </Form.Item>
        <Form.Item name="bookings__date" label="Booking">
          <DatePicker.RangePicker />
        </Form.Item>
        <Form.List name="progress" initialValue={[{}]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <Card
                  key={field.name}
                  actions={[
                    <RowRemoveButton
                      key="remove"
                      onClick={() => remove(field.name)}
                    />,
                  ]}>
                  <Form.Item
                    name={[field.name, 'change']}
                    label="Stage in Range">
                    <Select
                      allowClear
                      dropdownMatchSelectWidth={false}
                      options={stageData?.data.map((t: any) => ({
                        label: t.name,
                        value: t.name,
                      }))}
                    />
                  </Form.Item>
                  <Form.Item name={[field.name, 'created_at']}>
                    <DatePicker.RangePicker
                      ranges={{
                        Today: [dayjs(), dayjs()],
                        'This Month': [
                          dayjs().startOf('month'),
                          dayjs().endOf('month'),
                        ],
                        'This Week': [
                          dayjs().startOf('week'),
                          dayjs().endOf('week'),
                        ],
                      }}
                    />
                  </Form.Item>
                </Card>
              ))}
              <Button type="dashed" onClick={() => add()} block>
                Add Stage in Range
              </Button>
            </>
          )}
        </Form.List>
        <Form.List name="services" initialValue={[{}]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <Card
                  key={field.name}
                  actions={[
                    <RowRemoveButton
                      key="remove"
                      onClick={() => remove(field.name)}
                    />,
                  ]}>
                  <Form.Item name={[field.name, 'service']} label="Service">
                    <SelectWithDefault
                      allowClear
                      size="small"
                      popupMatchSelectWidth={false}
                      useSelectProps={{
                        resource: SERVICE_URL,
                        optionLabel: 'name',
                      }}
                    />
                  </Form.Item>
                  <Form.Item name={[field.name, 'status']}>
                    <Select
                      mode="multiple"
                      allowClear
                      options={serviceStageData?.data.map((t: any) => ({
                        label: t.name,
                        value: t.name,
                      }))}
                    />
                  </Form.Item>
                </Card>
              ))}
              <Button type="dashed" onClick={() => add()} block>
                Add Service Requirement
              </Button>
            </>
          )}
        </Form.List>
      </Form>
      <Modal {...modalProps} title="Save filter">
        <Form
          {...formProps}
          onFinish={(values) =>
            formProps.onFinish!({
              ...values,
              value: currentLocation.pathname + currentLocation.search,
            })
          }>
          <Form.Item name="name" label="Name">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </Drawer>
  )
}

const SavedFilterOption = ({ filter }: { filter: ISavedFilter }) => {
  const { mutate: destroy, isLoading } = useDelete()

  return (
    <div className="row">
      <span>{filter.name}</span>{' '}
      <Button
        type="text"
        size="small"
        danger
        loading={isLoading}
        icon={<Icons.DeleteOutlined />}
        onClick={(e) => {
          e.stopPropagation()
          destroy({ resource: SAVED_FILTERS_URL, id: filter.id })
        }}
      />
    </div>
  )
}

const ColumnDrawer = ({ userColumns, refetchColumns }: any) => {
  const [columns, setColumns] = useState<any>(userColumns)
  const [columnOrder, setColumnOrder] = useState<any>()

  const { mutate: create, isLoading } = useCreate()

  const handleColumnSave = () => {
    if (columnOrder) {
      columnOrder?.map(
        (c: any, index: number) =>
          (columns[c.id] = { ...columns[c.id], order: index + 1 }),
      )
      setColumns(columns)
    }

    create(
      {
        resource: CRM_PREFERENCES_URL + '/update_columns',
        values: { columns },
      },
      { onSuccess: () => refetchColumns() },
    )
  }

  useEffect(() => {
    setColumns(userColumns)
  }, [userColumns])

  const finalColumns = useMemo(() => mergeMaps(CRM_COLUMNS, columns), [columns])

  return (
    <MDDrawer
      control={
        <Button type="default" icon={<Icons.MenuOutlined />}>
          Columns
        </Button>
      }>
      <div
        style={{
          padding: 25,
        }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 25,
          }}>
          <Text>Select Columns</Text>
          <MDButton
            type="outline"
            color="success"
            loading={isLoading}
            onClick={() => handleColumnSave()}>
            Save
          </MDButton>
        </div>
        <SortableList
          items={Object.entries(finalColumns)
            .map(([id, value]: [string, any]) => ({
              id,
              ...value,
            }))
            .sort((a, b) => (a.order || 0) - (b.order || 0))}
          getId={(item) => item.id}
          onSortEnd={setColumnOrder}
          renderItem={(item) => (
            <SortableItem id={item.id} key={item.id} disabled={item.disabled}>
              <Checkbox
                defaultChecked={
                  columns[item.id]?.visible ||
                  ALWAYS_PRESENT_COLUMNS.includes(item.id)
                }
                disabled={item.disabled}
                onChange={(e) => {
                  setColumns({
                    ...columns,
                    [item.id]: { visible: e.target.checked },
                  })
                }}>
                {item.title}
              </Checkbox>
            </SortableItem>
          )}
        />
      </div>
    </MDDrawer>
  )
}

export const LeadList: React.FC = () => {
  const { data: userColumnData, refetch: refetchColumns } = useOne({
    resource: CRM_PREFERENCES_URL,
    id: 'get_columns',
  })

  const userColumns = useMemo(
    () => userColumnData?.data?.columns ?? CRM_COLUMNS,
    [userColumnData],
  )

  const {
    tableProps,
    sorters: sorter,
    searchFormProps,
    filters,
    tableQueryResult,
    setFilters,
  } = useTable<ILead>({
    syncWithLocation: true,

    onSearch: (data: any) => {
      return [
        { field: 'search', operator: 'eq', value: data.search },
        { field: 'campaign', operator: 'eq', value: data.campaign },
        { field: 'source', operator: 'eq', value: data.source },
        {
          field: 'pre_sales',
          operator: 'in',
          value: data.pre_sales?.join(','),
        },
        {
          field: 'pre_sales__isnull',
          operator: 'eq',
          value: data.pre_sales__isnull,
        },
        {
          field: 'relationship_manager',
          operator: 'in',
          value: data.relationship_manager?.join(','),
        },
        {
          field: 'relationship_manager__isnull',
          operator: 'eq',
          value: data.relationship_manager__isnull,
        },
        {
          field: 'consultant',
          operator: 'in',
          value: data.consultant?.join(','),
        },
        {
          field: 'consultant__isnull',
          operator: 'eq',
          value: data.consultant__isnull,
        },
        {
          field: 'venue',
          operator: 'in',
          value: data.venue?.join(','),
        },
        {
          field: 'event_horizon',
          operator: 'eq',
          value: data.event_horizon
            ? data.event_horizon.format('MMMM YYYY')
            : null,
        },
        {
          field: 'venue__isnull',
          operator: 'eq',
          value: data.venue__isnull,
        },
        {
          field: 'booking_probability',
          operator: 'in',
          value: data.booking_probability?.join(','),
        },
        {
          field: 'city',
          operator: 'in',
          value: data.city?.length ? data.city.join(',') : undefined,
        },
        {
          field: 'bookings__date',
          operator: 'between',
          value: data.bookings__date
            ?.map((date: dayjs.Dayjs) => date.format('YYYY-MM-DD'))
            .join(','),
        },
        {
          field: 'stage',
          operator: 'in',
          value: data.stage?.join(','),
        },
        {
          field: 'status',
          operator: 'in',
          value: data.status?.join(','),
        },
        {
          field: 'created_at',
          operator: 'between',
          value: !!data.created_at
            ? [
                data.created_at[0].startOf('day').toISOString(),
                data.created_at[1].endOf('day').toISOString(),
              ].join(',')
            : undefined,
        },
        {
          field: 'progress',
          operator: 'eq',
          value: !!data.progress
            ? data.progress?.map((item: any) =>
                !!item?.change
                  ? `${item.change}!${[
                      item.created_at[0].startOf('day').toISOString(),
                      item.created_at[1].endOf('day').toISOString(),
                    ].join(',')}`
                  : undefined,
              )
            : null,
        },
        {
          field: 'services',
          operator: 'eq',
          value:
            !!data.services &&
            !!data.services.length &&
            !!data.services[0].service
              ? data.services?.map(
                  (item: any) => `${item.service}!${item.status.join(',')}`,
                )
              : null,
        },
        {
          field: 'budget__gte',
          operator: 'gt',
          value: data.budget_gt,
        },
        {
          field: 'budget__lte',
          operator: 'lt',
          value: data.budget_lt,
        },
        {
          field: 'is_quiz_filled',
          operator: 'eq',
          value: data.is_quiz_filled,
        },
        {
          field: 'profile__isnull',
          operator: 'eq',
          value: data.profile__isnull,
        },
        {
          field: 'is_important',
          operator: 'eq',
          value: data.is_important,
        },
        {
          field: 'services__consultant',
          operator: 'in',
          value: data.services__consultant?.join(','),
        },
        {
          field: 'services__consultant__isnull',
          operator: 'eq',
          value: data.services__consultant__isnull,
        },
        {
          field: 'is_venue_decided',
          operator: 'eq',
          value: data.is_venue_decided,
        },
        {
          field: 'profile__rql',
          operator: 'eq',
          value: data.profile__rql,
        },
        {
          field: 'venue_suggestions__venue__id',
          operator: 'in',
          value: data.venue_suggestions?.join(','),
        },
        {
          field: 'venue_type',
          operator: 'eq',
          value: data.venue_type?.join(','),
        },
        {
          field: 'venue_price',
          operator: 'eq',
          value: data.venue_price?.join(','),
        },
        {
          field: 'tags__name',
          operator: 'in',
          value: data.tags?.join(','),
        },
      ]
    },

    pagination: {
      pageSize: 25,
    },

    sorters: {
      initial: [{ field: 'created_at', order: 'desc' }],
    },
  })

  const { show, close, visible } = useModal()

  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const { mutate } = useDeleteMany()
  const { mutate: patch, isLoading: isUpdating } = useUpdate()

  const { useHistory, useLocation } = useRouterContext()
  const history = useHistory()
  const currentLocation = useLocation()

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const { data: user } = useGetIdentity<IStaff>()

  const deleteMany = () => {
    mutate({
      resource: LEAD_URL,
      ids: selectedRowKeys,
    })
    setSelectedRowKeys([])
  }

  const handleVenueChange = (id: BaseKey, value: any) => {
    patch({
      resource: LEAD_URL,
      id,
      values: { venue: value, is_venue_decided: true },
    })
  }

  const handeIsVenueDecided = (id: BaseKey, value: boolean) => {
    patch({
      resource: LEAD_URL,
      id,
      values: { is_venue_decided: value },
    })
  }

  const handleSourceChange = (id: BaseKey, value: any) => {
    patch({
      resource: LEAD_URL,
      id,
      values: { source: value },
    })
  }

  const {
    drawerProps,
    formProps,
    show: showEdit,
    id: editId,
  } = useDrawerForm({
    action: 'edit',
    redirect: false,
  })

  const columns = [
    {
      className: 'tableHeader',
      title: 'Name',
      dataIndex: 'name',
      width: 350,
      ellipsis: true,
      fixed: true,
      visible: userColumns['name']?.visible,
      order: userColumns['name']?.order,
      render: (value: any, record: any) => (
        <Row>
          <Space direction="vertical" align="center" size="small">
            <Space align="baseline">
              <Typography.Title level={5}>{value}</Typography.Title>
              {record.is_important && (
                <Tooltip title="Important Lead">
                  <Icons.ExclamationOutlined
                    style={{
                      color: 'red',
                      fontSize: '18px',
                    }}
                  />
                </Tooltip>
              )}
              {record.is_quiz_filled && (
                <QuizDataModal leadId={record.id} leadName={record.name} />
              )}
              {record.profile && (
                <ProfileModal
                  leadName={record.name}
                  leadEmail={record.email}
                  leadPhoneNumber={record.phone}
                  profile={record.profile}
                />
              )}
              {record.is_existing && (
                <Tooltip title="This phone number already exists">
                  <FontAwesomeIcon icon={['fas', 'exchange-alt']} color="red" />
                </Tooltip>
              )}
            </Space>
            <>
              {(record.profile?.location ||
                record?.profile?.professional_level) && (
                <Space size="small">
                  {record.profile?.location_type && (
                    <Tooltip title={record.profile?.location ?? ''}>
                      <Tag>{record.profile?.location_type}</Tag>
                    </Tooltip>
                  )}
                  {record.profile?.professional_level && (
                    <Tooltip title={record.profile?.job_title ?? ''}>
                      <Tag>{record.profile?.professional_level}</Tag>
                    </Tooltip>
                  )}
                  {record.profile?.rql && <Tag>RQL</Tag>}
                </Space>
              )}
            </>
            <TagEditModal leadId={record.id} tags={record.tags} />
            <Space size="small">
              <EditButton
                hideText
                size="small"
                recordItemId={record.id}
                onClick={() => showEdit(record.id)}
              />
              <NotesInput leadId={record.id} notes={record.notes} />
              <CallLogModal lead={record} />
            </Space>
            <Space>
              <CreatePackageModal leadId={record.id} />
              <InfoModal leadId={record.id} />
              <LeadContext.Provider value={{ lead: record }}>
                <PreSaleQuiz lead={record.id} />
                <VenueRequirements
                  suggestions={record.venue_suggestions}
                  lead_id={record.id}
                  can_not_collect_venue_req={record.could_not_collect_venue_req}
                  phone={record.phone}
                  name={record.name}
                />
                <NewVenueRequirement />
              </LeadContext.Provider>
            </Space>
          </Space>
        </Row>
      ),
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      ellipsis: true,
      width: 200,
      visible: userColumns['phone']?.visible,
      order: userColumns['phone']?.order,
    },
    {
      title: 'Pre Sales',
      dataIndex: 'pre_sales',
      ellipsis: true,
      width: 260,
      visible: userColumns['pre_sales']?.visible,
      order: userColumns['pre_sales']?.order,
      render: (value: BaseKey, record: any) => (
        <div onClick={(e) => e.stopPropagation()}>
          <ConsultantSelect
            leadId={record.id}
            value={value}
            fieldName="pre_sales"
          />
        </div>
      ),
    },
    {
      title: 'RM',
      dataIndex: 'relationship_manager',
      ellipsis: true,
      width: 200,
      visible: userColumns['relationship_manager']?.visible,
      order: userColumns['relationship_manager']?.order,
      render: (value: BaseKey, record: any) => (
        <div onClick={(e) => e.stopPropagation()}>
          <ConsultantSelect
            leadId={record.id}
            value={value}
            fieldName="relationship_manager"
          />
        </div>
      ),
    },
    {
      title: 'Consultant',
      dataIndex: 'consultant',
      ellipsis: true,
      width: 200,
      visible: userColumns['consultant']?.visible,
      order: userColumns['consultant']?.order,
      render: (value: BaseKey, record: any) => (
        <div onClick={(e) => e.stopPropagation()}>
          <ConsultantSelect
            leadId={record.id}
            value={value}
            fieldName="consultant"
          />
        </div>
      ),
    },
    {
      title: 'Budget',
      dataIndex: 'budget',
      ellipsis: true,
      width: 200,
      visible: userColumns['budget']?.visible,
      order: userColumns['budget']?.order,
      render: (value: any, record: any) => {
        return (
          <BudgetInput
            id={record.id}
            budget_amount={value}
            could_not_collect_budget={record.could_not_collect_budget}
          />
        )
      },
    },
    {
      title: 'Call Attempt (Pre Sales)',
      dataIndex: 'call_attempt',
      ellipsis: true,
      width: 200,
      visible: userColumns['call_attempt']?.visible,
      order: userColumns['call_attempt']?.order,
      render: (callValue: any, record: any) => (
        <div onClick={(e) => e.stopPropagation()}>
          <CallAttemptInput
            leadId={record.id}
            callValue={callValue}
            lastCalled={record.call_attempt_date_time}
            name="call_attempt"
          />
        </div>
      ),
    },
    {
      title: 'Call Attempt (Sales)',
      dataIndex: 'sales_call_attempt',
      ellipsis: true,
      width: 200,
      visible: userColumns['sales_call_attempt']?.visible,
      order: userColumns['sales_call_attempt']?.order,
      render: (callValue: any, record: any) => (
        <div onClick={(e) => e.stopPropagation()}>
          <CallAttemptInput
            leadId={record.id}
            callValue={callValue}
            lastCalled={record.sales_call_attempt_date_time}
            name="sales_call_attempt"
          />
        </div>
      ),
    },
    {
      title: 'Stage',
      dataIndex: 'stages',
      ellipsis: true,
      width: 200,
      visible: userColumns['stages']?.visible,
      order: userColumns['stages']?.order,
      render: (_: any, record: any) => (
        <div onClick={(e) => e.stopPropagation()}>
          <ChangeSelect
            leadId={record.id}
            type="stage"
            changes={record.stages}
          />
        </div>
      ),
    },
    {
      title: 'Services',
      width: 250,
      visible: userColumns['services']?.visible,
      order: userColumns['services']?.order,
      render: (_: any, record: any) => (
        <ServicesModal leadId={record.id} services={record.services} />
      ),
    },
    {
      title: 'Events',
      ellipsis: true,
      width: 350,
      visible: userColumns['events']?.visible,
      order: userColumns['events']?.order,
      render: (_: any, record: any) => <EventsModal leadId={record.id} />,
    },
    {
      title: 'Venue',
      dataIndex: 'venue',
      ellipsis: true,
      width: 240,
      visible: userColumns['venue']?.visible,
      order: userColumns['venue']?.order,
      render: (venue: any, resource: any) => (
        <div
          style={{
            minWidth: 200,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <SelectWithDefault
            size="small"
            useSelectProps={{
              resource: VENUE_URL,
              optionLabel: 'name',
            }}
            defaultValue={venue}
            allowClear
            dropdownMatchSelectWidth={false}
            onChange={(value) => handleVenueChange(resource.id, value)}
            onClear={() => handleVenueChange(resource.id, null)}
          />
          <div>
            Venue Decided:{' '}
            <Switch
              checked={resource.is_venue_decided}
              onChange={(value) => handeIsVenueDecided(resource.id, value)}
            />
          </div>
        </div>
      ),
    },
    {
      title: 'Booking Probability',
      dataIndex: 'expected_booking_date',
      ellipsis: true,
      width: 200,
      visible: userColumns['expected_booking_date']?.visible,
      order: userColumns['expected_booking_date']?.order,
      render: (value: any, record: any) => <BookingProbability lead={record} />,
    },
    {
      title: 'City',
      dataIndex: 'city',
      width: 200,
      ellipsis: true,
      visible: userColumns['city']?.visible,
      order: userColumns['city']?.order,
      render: (value: any, record: any) => (
        <CityInput id={record.id} city_name={value} />
      ),
    },
    {
      title: 'Status',
      dataIndex: 'statuses',
      ellipsis: true,
      width: 200,
      visible: userColumns['statuses']?.visible,
      order: userColumns['statuses']?.order,
      render: (_: any, record: any) => (
        <div onClick={(e) => e.stopPropagation()}>
          <ChangeSelect
            leadId={record.id}
            type="status"
            changes={record.statuses}
          />
        </div>
      ),
    },
    {
      title: 'Source',
      dataIndex: 'source',
      width: 300,
      ellipsis: true,
      visible: userColumns['source']?.visible,
      order: userColumns['source']?.order,
      render: (_: any, record: any) => (
        <div onClick={(e) => e.stopPropagation()}>
          <Select
            style={{ width: '100%' }}
            loading={isUpdating}
            value={record.source}
            options={createOptionsFromEnum(sourceOptions)}
            onChange={(value) => handleSourceChange(record.id, value)}
          />
        </div>
      ),
    },
    {
      title: 'Event Horizon',
      dataIndex: 'event_horizon',
      ellipsis: true,
      width: 200,
      visible: userColumns['event_horizon']?.visible,
      order: userColumns['event_horizon']?.order,
    },
    {
      title: 'Campaign',
      dataIndex: 'campaign',
      width: 200,
      ellipsis: true,
      visible: userColumns['campaign']?.visible,
      order: userColumns['campaign']?.order,
    },
    {
      title: 'Project Value',
      dataIndex: 'project_value',
      width: 300,
      visible: userColumns['project_value']?.visible,
      order: userColumns['project_value']?.order,
      render: (project_value: any) =>
        !project_value ? '' : <PriceField value={project_value} />,
    },
    {
      title: 'Booking',
      width: 200,
      visible: userColumns['booking']?.visible,
      order: userColumns['booking']?.order,
      render: (_: any, record: any) => <BookingModal leadId={record.id} />,
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      sorter: true,
      ellipsis: true,
      width: 200,
      visible: userColumns['created_at']?.visible,
      order: userColumns['created_at']?.order,
      defaultSortOrder: getDefaultSortOrder('created_at', sorter),
      render: (value: any) => <DateFieldFromNow value={value} />,
    },
  ]
    .filter((item) => item.visible)
    .sort((a, b) => a.order - b.order)

  return (
    <List
      title={
        <>
          {`Leads (${tableQueryResult.data?.total})`}
          {tableQueryResult.isFetching && (
            <Typography.Text>
              Syncing <Spin size="small" />
            </Typography.Text>
          )}
        </>
      }
      headerButtons={({ defaultButtons }) => (
        <>
          {!!selectedRowKeys.length && (
            <DeleteButton key="delete" onClick={deleteMany} />
          )}

          <SelectWithDefault<ISavedFilter>
            dropdownMatchSelectWidth={false}
            placeholder="Saved Filters"
            size="small"
            useSelectProps={{
              resource: SAVED_FILTERS_URL,
              filters: [{ field: 'user', operator: 'eq', value: user?.id }],
              optionLabel: 'name',
              optionValue: 'value',
            }}
            onSelect={(value) => {
              searchFormProps.form?.resetFields()
              const parsedParams = parseTableParams(value)
              searchFormProps.form?.setFieldsValue(
                getSearchFormInitialValue(
                  parsedParams.parsedFilters,
                  crmFilterFieldFormatter,
                ),
              )
              setFilters(parsedParams.parsedFilters, 'replace')
            }}
            renderOptions={(options) =>
              options?.map((option) => (
                <Select.Option key={option.id} value={option.value}>
                  <SavedFilterOption filter={option} />
                </Select.Option>
              ))
            }
          />
          <ColumnDrawer
            userColumns={userColumns}
            refetchColumns={refetchColumns}
          />
          <Button type="primary" onClick={show} icon={<Icons.FilterFilled />}>
            Filters
          </Button>
          {defaultButtons}
          <RefreshButton
            key="refresh"
            onClick={() => tableQueryResult.refetch()}
          />
        </>
      )}>
      <FilterDrawer
        searchFormProps={searchFormProps}
        filters={filters}
        close={close}
        visible={visible}
      />
      <Table
        {...tableProps}
        size="small"
        bordered
        rowKey="id"
        columns={columns as any}
        rowClassName={(record) => (record.is_existing ? 'existing-row' : '')}
        expandable={{
          expandedRowRender: (record: any) => (
            <LeadContext.Provider
              value={{ lead: record, refetch: tableQueryResult.refetch }}>
              <ServiceStatusTable
                lead_id={record.id}
                budget={record.budget}
                could_not_collect_budget={record.could_not_collect_budget}
                could_not_collect_venue_req={record.could_not_collect_venue_req}
              />
            </LeadContext.Provider>
          ),
          fixed: true,
        }}
        scroll={{ y: '70vh' }}
        rowSelection={rowSelection}
      />
      <Drawer
        {...drawerProps}
        width={800}
        extra={
          <Button type="primary" onClick={() => formProps.form.submit()}>
            Save
          </Button>
        }>
        {drawerProps.open && (
          <LeadForm
            formProps={formProps}
            id={editId}
            data={tableQueryResult.data?.data.find(
              (item) => item.id === editId,
            )}
          />
        )}
      </Drawer>
    </List>
  )
}
