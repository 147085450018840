import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  BooleanField,
  DateField,
  getDefaultSortOrder,
  useTable,
} from '@refinedev/antd'

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons'

import { useNavigation } from '@refinedev/core'
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
} from 'antd'
import { SelectWithDefault } from 'components/input/SelectWithDefault'

import dayjs from 'antd/node_modules/dayjs'
import type { Dayjs } from 'dayjs'
import {
  IEventCalendar,
  IEventCalendarPackage,
} from 'interfaces/shortlistPackage'
import {
  EVENT_CALENDAR_URL,
  REGION_URL,
  RFQ_URL,
  SERVICE_URL,
  SHORTLIST_PACKAGE_URL,
  STAFF_URL,
} from 'urls'
import { getSearchFormInitialValue } from 'utils/filter'
import { IStaff } from 'interfaces/staff'
import { IRegion } from 'interfaces/region'

export const Dashboard = () => {
  const { edit } = useNavigation()

  const thisMonth = [
    dayjs().startOf('month'),
    dayjs().endOf('month').add(1, 'day'),
  ]

  const {
    tableProps,
    searchFormProps,
    sorters: sorter,
    tableQueryResult,
    filters,
  } = useTable<
    IEventCalendar,
    any,
    {
      search: string
      date_time: any[]
      service: number
      consultant: number[]
      project_manager: number[]
    }
  >({
    resource: EVENT_CALENDAR_URL,
    syncWithLocation: true,

    onSearch: ({ search, date_time, service, consultant, project_manager }) => [
      {
        field: 'search',
        operator: 'eq',
        value: search,
      },
      {
        field: 'date_time',
        operator: 'between',
        value: date_time.map((value) => value.format('YYYY-MM-DD')).join(','),
      },
      {
        field: 'service',
        operator: 'eq',
        value: service,
      },
      {
        field: 'package__consultant',
        operator: 'in',
        value: consultant?.join(),
      },
      {
        field: 'package__project_manager',
        operator: 'in',
        value: project_manager?.join(),
      },
    ],
    pagination: {
      pageSize: 25,
    },

    filters: {
      initial: [
        {
          field: 'date_time',
          operator: 'between',
          value: thisMonth.map((value) => value.format('YYYY-MM-DD')).join(','),
        },
        {
          field: 'service',
          operator: 'eq',
          value: 1,
        },
      ],
    },

    sorters: {
      initial: [{ field: 'date_time', order: 'desc' }],
    },
  })

  return (
    <>
      <Typography.Title level={3}>
        Event Calendar ({tableQueryResult.data?.total})
      </Typography.Title>
      <Form
        {...searchFormProps}
        key="search"
        initialValues={getSearchFormInitialValue(filters, {
          date_time: (value: any) => value?.map((date: string) => dayjs(date)),
        })}
        size="small"
        layout="inline">
        <Form.Item name="date_time" label="Date">
          <DatePicker.RangePicker
            ranges={{
              Today: [dayjs(), dayjs().add(1, 'day')],
              'This Month': [
                dayjs().startOf('month'),
                dayjs().endOf('month').add(1, 'day'),
              ],
              'This Week': [
                dayjs().startOf('week'),
                dayjs().endOf('week').add(1, 'day'),
              ],
            }}
            onChange={(dates) =>
              dates?.length === 2 && searchFormProps?.form?.submit()
            }
          />
        </Form.Item>
        <Form.Item name="service" label="Service">
          <SelectWithDefault
            useSelectProps={{ resource: SERVICE_URL, optionLabel: 'name' }}
            popupMatchSelectWidth={false}
            onChange={() => searchFormProps?.form?.submit()}
          />
        </Form.Item>
        <Form.Item name="consultant" label="Consultant">
          <SelectWithDefault<IStaff>
            useSelectProps={{
              resource: STAFF_URL,
            }}
            mode="multiple"
            style={{
              width: '150px',
            }}
            onChange={searchFormProps.form?.submit}
            renderOptions={(staffs?: IStaff[]) =>
              staffs?.map((staff: IStaff) => (
                <Select.Option key={staff.id} value={staff.id}>
                  {staff.first_name} {staff.last_name}
                </Select.Option>
              ))
            }
          />
        </Form.Item>
        <Form.Item name="project_manager" label="Project Manager">
          <SelectWithDefault<IStaff>
            useSelectProps={{
              resource: STAFF_URL,
            }}
            mode="multiple"
            style={{
              width: '150px',
            }}
            onChange={searchFormProps?.form?.submit}
            renderOptions={(staffs?: IStaff[]) =>
              staffs?.map((staff: IStaff) => (
                <Select.Option key={staff.id} value={staff.id}>
                  {staff.first_name} {staff.last_name}
                </Select.Option>
              ))
            }
          />
        </Form.Item>
      </Form>

      <Divider />
      <Table {...tableProps} size="small" bordered>
        <Table.Column dataIndex={['package', 'event']} title="PID" ellipsis />
        <Table.Column
          dataIndex="date_time"
          title="Date Time"
          sorter
          ellipsis
          defaultSortOrder={getDefaultSortOrder('date_time', sorter)}
          render={(value) => <DateField value={value} format="LLL" />}
        />
        <Table.Column dataIndex={['section', 'name']} title="Event" ellipsis />
        <Table.Column dataIndex={['venue', 'name']} title="Venue" ellipsis />
        <Table.Column<IEventCalendar>
          dataIndex={['package', 'customer']}
          title="Customer"
          ellipsis
          render={(customer) =>
            `${customer.first_name} ${customer.last_name ?? ''}`
          }
        />
        <Table.Column<IEventCalendar>
          dataIndex={['package', 'consultant']}
          title="Planner"
          ellipsis
          render={(consultant) =>
            !!consultant
              ? `${consultant.first_name} ${consultant.last_name ?? ''}`
              : ''
          }
        />
        <Table.Column<IEventCalendar>
          dataIndex={['package', 'project_manager']}
          title="PM"
          ellipsis
          render={(pm) =>
            !!pm ? `${pm.first_name} ${pm.last_name ?? ''}` : ''
          }
        />
        <Table.Column
          dataIndex="dismantling_date_time"
          title="Dismantling D/T"
          ellipsis
          render={(value) =>
            value ? <DateField value={value} format="LLL" /> : ''
          }
        />
        <Table.Column<IEventCalendar>
          dataIndex={['package', 'is_finalized']}
          title="Finalized"
          ellipsis
          render={(value: boolean) => <BooleanField value={value} />}
        />
        <Table.Column<IEventCalendar>
          dataIndex={['package', 'rfq_locked']}
          title="Locked"
          ellipsis
          render={(value: boolean) => <BooleanField value={value} />}
        />
        <Table.Column<IEventCalendar>
          dataIndex={['package', 'is_executed']}
          title="Executed"
          ellipsis
          render={(value: boolean) => <BooleanField value={value} />}
        />
        {/* <Table.Column<IEventCalendar>
          dataIndex={['package', 'discount_amount']}
          title="Package Value"
          ellipsis
          render={(discount_amount: number, record) => (
            <PriceField value={record.price - discount_amount} />
          )}
        />
        <Table.Column<IEventCalendar>
          dataIndex={['package', 'discount_amount']}
          title="Pending Amount"
          ellipsis
          render={(discount_amount: number, record) => (
            <PriceField
              value={record.price - discount_amount - record.collected}
            />
          )}
        /> */}
        <Table.Column<IEventCalendar>
          dataIndex={['package']}
          title="Actions"
          ellipsis
          render={(record: IEventCalendarPackage) => (
            <Space size="small">
              <Tooltip title="Goto Package">
                <Button
                  size="small"
                  icon={<FontAwesomeIcon icon={['fas', 'cubes']} />}
                  onClick={() => edit(SHORTLIST_PACKAGE_URL, record.id)}
                />
              </Tooltip>
              <Tooltip title="Share">
                <Button
                  size="small"
                  icon={<Icons.ShareAltOutlined />}
                  onClick={() => {
                    window.open(
                      `${process.env.REACT_APP_WEB_APP_URL}/${record.id}`,
                      '_blank',
                    )
                  }}></Button>
              </Tooltip>

              {/* Only Packages which are finalized will have a RFQ link */}

              {record.is_finalized && (
                <Tooltip title="RFQ">
                  <Button
                    size="small"
                    icon={<Icons.UnorderedListOutlined />}
                    onClick={() => {
                      window.open(`${RFQ_URL}/show/${record.id}`, '_blank')
                    }}></Button>
                </Tooltip>
              )}
            </Space>
          )}
        />
      </Table>
    </>
  )
}
