import { Form, FormProps, Input } from "antd";

export const RegionForm: React.FC<{ formProps: FormProps }> = ({
  formProps,
}) => {
  const displaySuggestions = function (predictions: any, status: any) {
    if (
      // @ts-ignore
      status != window.google.maps.places.PlacesServiceStatus.OK ||
      !predictions
    ) {
      console.log("statues", status);
      return;
    }
    console.log(predictions);
    // predictions.forEach((prediction: any) => {
    //   const li = document.createElement("li");

    //   li.appendChild(document.createTextNode(prediction.description));
    //   (document.getElementById("results") as HTMLUListElement).appendChild(li);
    // });
  };

  const onSearch = (searchText: string) => {
    const options = {
      componentRestrictions: { country: "in" },
      strictBounds: false,
      types: ["cities"],
    };
    // @ts-ignore
    const autocomplete = new window.google.maps.places.AutocompleteService();
    console.log(
      autocomplete.getPlacePredictions(
        { input: searchText },
        displaySuggestions
      )
    );
  };
  return (
    <Form {...formProps} layout="vertical">
      <Form.Item label="Name" name="name">
        <Input />
      </Form.Item>
      <Form.Item label="City" name="city">
        <Input />
      </Form.Item>
    </Form>
  );
};
