import * as Icons from '@ant-design/icons'
import {
  DateField,
  ExportButton,
  getDefaultSortOrder,
  List,
  useTable,
} from '@refinedev/antd'
import { BaseKey, useExport, useNavigation } from '@refinedev/core'
import { Button, DatePicker, Form, Table, Tooltip } from 'antd'
import { CheckAccess } from 'components/checkAccess'
import { PriceField } from 'components/field'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import dayjs from 'antd/node_modules/dayjs'
import type { Dayjs } from 'dayjs'
import { IPayment, IPaymentDetailed } from 'interfaces/payment'
import { SERVICE_URL } from 'urls'
import { getSearchFormInitialValue } from 'utils/filter'

export const PaymentList: React.FC = () => {
  const tProps = useTable<
    IPayment,
    any,
    { search: string; transaction_date_time: any[]; service: BaseKey }
  >({
    syncWithLocation: true,

    onSearch: ({ search, transaction_date_time, service }) => [
      {
        field: 'search',
        operator: 'eq',
        value: search,
      },
      {
        field: 'transaction_date_time',
        operator: 'between',
        value: transaction_date_time
          ? transaction_date_time
              .map((value) => value.format('YYYY-MM-DD'))
              .join(',')
          : null,
      },
      {
        field: 'service',
        operator: 'eq',
        value: service,
      },
    ],

    sorters: {
      initial: [{ field: 'created_at', order: 'desc' }],
    },
  })
  const { show } = useNavigation()
  const { triggerExport, isLoading: exportLoading } =
    useExport<IPaymentDetailed>({
      mapData: (item) => {
        return {
          project_id: item?.event,
          cf_order_id: item?.order_id,
          customer_first_name: item?.customer?.first_name,
          customer_last_name: item?.customer?.last_name,
          customer_phone_number: item?.customer?.username,
          amount: item?.amount,
          type: item?.type?.name,
          service: item?.service_name,
          created_at: item?.created_at,
          updated_at: item?.updated_at,
          transaction_date_time: item?.transaction_date_time,
          status: item?.status,
        }
      },

      pageSize: 100,
      filters: tProps.filters,
      sorters: tProps.sorter,
    })

  return (
    <CheckAccess resource="access_payments" action="">
      <List
        headerButtons={({ defaultButtons }) => (
          <>
            {defaultButtons}
            <ExportButton
              key="export"
              onClick={triggerExport}
              loading={exportLoading}
            />
          </>
        )}>
        <Form
          {...tProps.searchFormProps}
          initialValues={getSearchFormInitialValue(tProps.filters, {
            transaction_date_time: (value: any) =>
              value.map((date: string) => dayjs(date, 'YYYY-MM-DD')),
          })}
          key="search"
          layout="inline">
          <Form.Item name="transaction_date_time" label="Transaction Date">
            <DatePicker.RangePicker
              presets={[
                { label: 'Today', value: [dayjs(), dayjs()] },
                {
                  label: 'This Month',
                  value: [dayjs().startOf('month'), dayjs().endOf('month')],
                },
              ]}
              onChange={(dates) => {
                if (!dates?.length || dates?.length === 2)
                  tProps.searchFormProps?.form?.submit()
              }}
            />
          </Form.Item>
          <Form.Item label="Service" name="service">
            <SelectWithDefault
              size="small"
              style={{
                width: '200px',
              }}
              useSelectProps={{ resource: SERVICE_URL, optionLabel: 'name' }}
              onChange={(service) => {
                tProps.searchFormProps?.form?.submit()
              }}
            />
          </Form.Item>
        </Form>
        <Table
          {...tProps.tableProps}
          rowKey="id"
          size="small"
          onRow={(record) => ({
            onClick: (event) => show('package_tool/payments', record.id),
          })}>
          <Table.Column dataIndex="event" title="Project ID" ellipsis />
          <Table.Column<IPayment>
            dataIndex="customer"
            title="Customer"
            ellipsis
            render={(value) =>
              `${value?.first_name} ${value?.last_name} (${value?.username})`
            }
          />
          <Table.Column<IPayment>
            dataIndex="amount"
            title="Amount"
            sorter
            defaultSortOrder={getDefaultSortOrder('amount', tProps.sorter)}
            render={(value) => <PriceField value={value} />}
          />
          <Table.Column dataIndex={['type', 'name']} title="Type" />
          <Table.Column
            dataIndex={['service_name']}
            title="Service"
            render={(service) => service ?? ''}
          />
          <Table.Column dataIndex={['status']} title="Status" />
          <Table.Column<IPayment>
            dataIndex="created_at"
            title="Created At"
            sorter
            ellipsis
            defaultSortOrder={getDefaultSortOrder('created_at', tProps.sorter)}
            render={(value) => <DateField value={value} format="LLL" />}
          />
          <Table.Column<IPayment>
            dataIndex="transaction_date_time"
            title="Transaction at"
            sorter
            ellipsis
            defaultSortOrder={getDefaultSortOrder(
              'transaction_date_time',
              tProps.sorter,
            )}
            render={(value) => <DateField value={value} format="LLL" />}
          />
          <Table.Column<IPayment>
            dataIndex="updated_at"
            title="Updated At"
            sorter
            ellipsis
            defaultSortOrder={getDefaultSortOrder('updated_at', tProps.sorter)}
            render={(value) => <DateField value={value} format="LLL" />}
          />
          <Table.Column
            dataIndex="event"
            title="Actions"
            ellipsis
            render={(value) =>
              !!value && (
                <span onClick={(e) => e.stopPropagation()}>
                  <Tooltip title="Goto Project">
                    <Button
                      icon={<Icons.SnippetsOutlined />}
                      onClick={() => show('package_tool/events', value)}
                    />
                  </Tooltip>
                </span>
              )
            }
          />
        </Table>
      </List>
    </CheckAccess>
  )
}
