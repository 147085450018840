import { DateField, useForm } from '@refinedev/antd'
import { Space, Spin, Timeline, Typography } from 'antd'
import { useApiUrl, useCan, useCustom } from '@refinedev/core'
import { IPayment, IPaymentWithBreakdown } from 'interfaces/payment'
import React from 'react'

import { IEvent } from 'interfaces/customer'
import { TimelinePaymentCard } from 'pages/package_tool/shortlistPackage/components/TimelineCard'
import { AddPaymentModal } from './AddPaymentModel'
import { CollectPaymentModal } from './CollectPaymentModal'

export const ProjectPayment: React.FC<{
  project_id: number
  event: IEvent
  refetchPayments: any
}> = ({ project_id, event, refetchPayments }) => {
  const apiUrl = useApiUrl()

  const useFormProps = useForm<IPayment>({
    action: 'create',
    resource: 'package_tool/payments',
    redirect: false,
    onMutationSuccess: () => {
      refetchPayments()
    },
  })

  const { data: addPayment } = useCan({
    resource: 'add_custom_payment',
    action: '',
  })

  const {
    data: payments,
    refetch: paymentsRefetch,
    isFetching: paymentFetching,
  } = useCustom<IPaymentWithBreakdown>({
    url: `${apiUrl}/package_tool/payments/get_from_project/`,
    method: 'get',
    config: {
      filters: [
        {
          field: 'project_id',
          operator: 'eq',
          value: project_id,
        },
      ],
    },
  })

  return (
    <div>
      {paymentFetching && (
        <p>
          Syncing
          <Spin size="small" />
        </p>
      )}
      <Space>
        <CollectPaymentModal
          useFormProps={useFormProps}
          projectId={project_id}
        />
        {addPayment?.can && (
          <AddPaymentModal useFormProps={useFormProps} project={event} />
        )}
      </Space>
      <Timeline mode="left" reverse style={{ marginTop: '30px' }}>
        {payments?.data.items.map((payment) => (
          <Timeline.Item
            key={payment.id}
            label={
              <Space direction="vertical">
                <Typography.Text>
                  Created At:{' '}
                  {<DateField value={payment.created_at} format="LLL" />}
                </Typography.Text>
                <Typography.Text>
                  Last Updated:{' '}
                  {<DateField value={payment.updated_at} format="LLL" />}
                </Typography.Text>
                <Typography.Text>
                  Transaction Time:{' '}
                  {
                    <DateField
                      value={payment.transaction_date_time}
                      format="LLL"
                    />
                  }
                </Typography.Text>
              </Space>
            }>
            <TimelinePaymentCard
              payment={payment}
              refetchPayments={refetchPayments}
              paymentsRefetch={paymentsRefetch}
            />
          </Timeline.Item>
        ))}
      </Timeline>
    </div>
  )
}
